/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  width: 100%;
  max-width: 89.58333333%;
}
#home {
  width: 46px;
  height: 36px;
  margin-top: 12px;
  margin-left: 37px;
}
#head {
  margin-top: 60px;
}
#header {
  padding-right: 120px;
}
#multimood {
  margin-top: 60px;
}
#multimood div.link {
  display: block;
}
.cb-layout2 #content,
.cb-layout3 #content,
.cb-layout4 #content,
.cb-layout5 #content {
  margin-top: 60px;
}
.opennavi {
  width: 40px;
  height: 26px;
  margin: 17px 0 17px 40px;
}
#logobg {
  width: 120px;
  height: 60px;
  z-index: 12;
}
#find {
  display: block;
}
.cb-layout2 h1 {
  max-width: 89.58333333%;
}
#view div.fold a.link.more,
#view div.flat a.link.more {
  top: 0;
}
div.fold div.load,
div.flat div.load {
  position: absolute;
  right: 21px;
  top: 15px;
  z-index: 3;
  width: 26px !important;
  margin: 0 !important;
}
div.fold div.load a.load:hover,
div.flat div.load a.load:hover,
div.fold div.load a.load:focus,
div.flat div.load a.load:focus {
  background-image: url(/images/fold-load-active.svg);
}
div.cb-slideshow div.head div.ctrl div.prev {
  margin-left: 5%;
}
div.cb-slideshow div.head div.ctrl div.next {
  margin-right: 5%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.5%;
  margin-left: 1.5%;
}
.area .part,
.area > .grid table {
  margin-right: 1.5%;
  margin-left: 1.5%;
  width: 97%;
}
.area .tiny {
  width: 47%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 3%;
  margin-left: 3%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 94%;
}
.area > .slim .tiny {
  width: 44%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
div.main {
  width: 103%;
  margin-left: -1.5%;
}
.cb-layout2 div.main {
  width: 100%;
  margin-left: 0;
}
.cb-layout2 div.main div.unit {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.cb-layout2 div.main div.unit div.head,
.cb-layout2 div.main div.unit div.body,
.cb-layout2 div.main div.unit div.foot {
  width: 89.58333333%;
}
.cb-layout2 div.main div.unit div.part,
.cb-layout2 div.main div.unit table,
.cb-layout2 div.main div.unit div.head h2 {
  margin-left: 0;
  margin-right: 0;
}
.cb-layout2 div.main div.unit div.part {
  width: 100%;
}
.cb-layout3 div.main div.seam {
  margin-left: 1.5%;
  margin-right: 1.5%;
  width: 97%;
}
.cb-layout3 div.main div.seam div.head h2,
.cb-layout3 div.main div.seam div.part {
  margin-left: 0;
  margin-right: 0;
}
.cb-layout3 div.main div.seam div.part {
  width: 100%;
}
.cb-layout3 div.main div.seam.slim {
  width: 47%;
}
.cb-layout5 div.main {
  width: 100%;
  margin-left: 0;
}
.cb-layout5 div.main div.unit {
  width: 100%;
}
.cb-layout5 div.main div.unit div.head h2,
.cb-layout5 div.main div.unit div.part {
  margin-left: 0;
  margin-right: 0;
}
.cb-layout5 div.main div.unit div.tall {
  float: right;
  width: 56.39534884%;
}
.cb-layout5 div.main div.unit div.tiny {
  float: left;
  width: 39.24418605%;
}
div.base {
  width: 103%;
  margin-left: -1.5%;
}
div.base div.unit {
  margin-left: 1.5%;
  margin-right: 1.5%;
  width: 47%;
}
div.base div.unit div.head h2,
div.base div.unit div.part {
  margin-left: 4%;
  margin-right: 4%;
}
div.base div.unit div.part {
  width: 92%;
}
div.farnorth {
  width: 100%;
}
div.farnorth div.unit {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
div.farnorth div.unit div.head h2,
div.farnorth div.unit div.part {
  margin-left: 0;
  margin-right: 0;
}
div.farnorth div.unit div.part {
  width: 100%;
}
div.north {
  margin-left: 0;
  width: 100%;
}
div.north div.unit {
  margin-left: 0;
  margin-right: 0;
}
div.north div.unit.wide {
  width: 100%;
}
div.north div.unit.slim {
  width: 39.24418605%;
}
div.north div.unit div.head h2,
div.north div.unit div.part {
  margin-left: 0;
  margin-right: 0;
}
div.north div.unit div.part {
  width: 100%;
}
.cb-layout5 div.main div.unit div.pict {
  margin-bottom: 20px;
}
.cb-layout3 div.main div.fold,
.cb-layout3 div.main div.flat {
  margin-left: 1.5%;
  margin-right: 1.5%;
  width: 97%;
}
.cb-layout3 div.main div.fold div.head h2,
.cb-layout3 div.main div.flat div.head h2,
.cb-layout3 div.main div.fold div.part,
.cb-layout3 div.main div.flat div.part,
.cb-layout3 div.main div.fold div.foot,
.cb-layout3 div.main div.flat div.foot {
  margin-left: 0;
  margin-right: 0;
}
.cb-layout3 div.main div.fold div.part,
.cb-layout3 div.main div.flat div.part {
  width: 100%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #50162d;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #50162d;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #280b17;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-manual > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
.aspt,
.cb-sort-mode {
  font-size: 16px;
  font-size: 1.6rem;
  display: inline-block;
  padding: 0.1125em 1em;
  min-height: 1.2em;
  border: 1px solid;
  border-color: #3c1122 #14060b #14060b #3c1122;
  border-radius: 2px;
  background-color: #50162d;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75);
  line-height: 1.2em;
  float: left;
  text-shadow: none;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  border-color: #3c1122 #14060b #14060b #3c1122;
  background-color: #5c1934;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  border-color: #3c1122 #14060b #14060b #3c1122;
  background-color: #280b17;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.aspt {
  margin-right: 0.5em;
}
/******* module-filter-medium.less 2013-1-16 *******/
.cb-mobile-filter-items > .beta > span {
  width: 14.28571429%;
}
/*# sourceMappingURL=./screen-medium.css.map */